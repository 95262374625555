import React from "react"
import {Link} from "gatsby"
import './menu.scss';
import BottomLogo from '../../images/bottom_footer_svg.svg';
// import SocialMedia from '../social_menu/index';

// Menu Component
export default function Menu(props) {
  console.log("footer menu");
  console.log(props);
  return (
    <div className="menu">
      
      
         {props.data.map((list) => (
          <div className="menu_item">

          <h2>{list.title}</h2>
            <ul >
            {list.addMenuItem.map((node) =>(
              <li>
             {/* can't test something that isn't there */}
             {node.linkToPost && <Link className="internal" to={"/" + node.linkToPost.slug + "/"}> {node.linkToPost.title}</Link>}
          {node.isThisExternalLink && <a href={node.externalLink}>{node.title}</a> }
         
              </li>
            ))}
            </ul>
            </div>
          ))}   
      <LogoWhite>
         {/* <SocialMedia /> */}
      </LogoWhite>
    </div>
    )
}




function LogoWhite(props){

  return(
    <div className="bottom_container">
          {/* <img className="bottom_logo" src={BottomLogo} alt=""></img> */}
          <object type="image/svg+xml" data={BottomLogo}></object>
          {/* <svg class="icon">
            <use xlinkHref={BottomLogo}></use>
        </svg> */}
        {props.children}
      </div>
  )
}