import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Header from './header';

const HeaderQuery = ({children}) => {
  console.log("HeaderQuery");
  const data = useStaticQuery(graphql`
  {
    datoCmsSiteSetting {
      mainLogo {
        url
      }
      mobileLogo {
        url
      }
      bottomFooterMessage
      contactInfo
    }
  }
  
  `)
  return <Header data={data}></Header>
}

export default HeaderQuery