import { Link } from "gatsby"
import Nav from '../nav/index'
import './header.scss'
import React, { useState, useEffect } from 'react';

export default function Header(props) {

  const [MobileMenu, setMobileMenu] = useState(false);
  console.log(props);

  return (
      <div className="container-fluid">
        <Link to="/">

          {/* <div className="logo">
          </div> */}
          <img className="header-logo" src={props.data.datoCmsSiteSetting.mainLogo.url} />
        </Link>

        <button className="menu--small" onKeyDown={() => setMobileMenu(!MobileMenu)} onClick={() => setMobileMenu(!MobileMenu)}>
          <div>
            <div className="navMenu"></div>
            <div className="navMenu"></div>
            <div className="navMenu"></div>
          </div>
        </button>
        <div>

          <Nav mobileStatus={MobileMenu} />
          
          <div className="topNav">
            <a target="_blank" href="http://www.linkedin.com/in/andrewcrowenz">
              <svg xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15ZM30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15ZM11.0058 21.8179C11.2936 21.8179 11.527 21.5846 11.527 21.2967V11.9958C11.527 11.7079 11.2936 11.4746 11.0058 11.4746H8.78702C8.49915 11.4746 8.2658 11.7079 8.2658 11.9958V21.2967C8.2658 21.5846 8.49915 21.8179 8.78702 21.8179H11.0058ZM7.78858 8.49002C7.78858 9.65412 8.73229 10.5978 9.89639 10.5978C11.0606 10.5978 12.0042 9.65412 12.0042 8.49002C12.0042 7.32591 11.0605 6.3822 9.89639 6.3822C8.73229 6.3822 7.78858 7.32591 7.78858 8.49002ZM22.6428 21.8179C22.9075 21.8179 23.1221 21.6033 23.1221 21.3387V16.8372V16.804C23.1222 14.8402 23.1224 11.3185 19.3315 11.3185C17.6099 11.3185 16.8013 11.9487 16.2572 12.7653V11.9538C16.2572 11.6892 16.0426 11.4746 15.778 11.4746H13.4753C13.2106 11.4746 12.996 11.6892 12.996 11.9538V21.3387C12.996 21.6033 13.2106 21.8179 13.4753 21.8179H15.778C16.0427 21.8179 16.2572 21.6033 16.2572 21.3387V16.3068C16.317 15.6307 16.6146 14.1242 18.0819 14.1242C19.8343 14.1242 19.7995 16.0129 19.7849 16.8085C19.7837 16.8717 19.7827 16.9281 19.7827 16.976V21.3387C19.7827 21.6033 19.9973 21.8179 20.262 21.8179H22.6428Z"/>
            </svg>
            </a>

            <a  target="_blank" href="http://www.instragram.com">
            <svg xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3 11.4205C13.1608 11.4205 11.4205 13.1608 11.4205 15.3C11.4205 17.4391 13.1608 19.1795 15.3 19.1795C17.4392 19.1795 19.1795 17.4392 19.1795 15.3C19.1795 13.1608 17.4392 11.4205 15.3 11.4205ZM15.3 17.6705C13.9908 17.6705 12.9295 16.6092 12.9295 15.3C12.9295 13.9908 13.9908 12.9295 15.3 12.9295C16.6092 12.9295 17.6705 13.9908 17.6705 15.3C17.6705 16.6092 16.6092 17.6705 15.3 17.6705Z"/>
                <path d="M20.1165 11.4496C20.1165 11.963 19.7003 12.3792 19.1869 12.3792C18.6735 12.3792 18.2573 11.963 18.2573 11.4496C18.2573 10.9362 18.6735 10.52 19.1869 10.52C19.7003 10.52 20.1165 10.9362 20.1165 11.4496Z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2686 7.80005H18.3314C20.7954 7.80005 22.8 9.80464 22.8 12.2686V18.3315C22.8 20.7955 20.7954 22.8 18.3314 22.8H12.2686C9.80458 22.8 7.79999 20.7955 7.79999 18.3315V12.2686C7.79999 9.80464 9.80458 7.80005 12.2686 7.80005ZM18.3314 21.291C19.9659 21.291 21.291 19.966 21.291 18.3315V12.2686C21.291 10.6341 19.9659 9.30905 18.3314 9.30905H12.2686C10.6341 9.30905 9.30899 10.6341 9.30899 12.2686V18.3315C9.30899 19.966 10.6341 21.291 12.2686 21.291H18.3314Z" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15ZM29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15Z"/>
            </svg>

            </a>

            <a  target="_blank" href="https://www.facebook.com/andrewcroweuk/">
            <svg xmlns="http://www.w3.org/2000/svg">
            <path d="M12.7715 24.5866V16.8159H10.6989C10.4296 16.8159 10.211 16.6037 10.21 16.3414L10.2 13.8366C10.199 13.5728 10.4182 13.3584 10.6891 13.3584H12.7579V10.9381C12.7579 8.12945 14.5189 6.6001 17.0912 6.6001H19.2019C19.472 6.6001 19.691 6.81336 19.691 7.07646V9.18851C19.691 9.45151 19.4721 9.66471 19.2022 9.66487L17.9069 9.66546C16.508 9.66546 16.2371 10.3129 16.2371 11.2632V13.3585H19.3109C19.6038 13.3585 19.8311 13.6076 19.7965 13.8909L19.4918 16.3957C19.4626 16.6354 19.2539 16.816 19.0061 16.816H16.2508L16.2371 24.5866H12.7715Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15ZM29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15Z"/>
            </svg>


            </a>
          
          </div>
          {props.data.datoCmsSiteSetting.contactInfo && 
          <div className="phoneNumber">
            <p>{props.data.datoCmsSiteSetting.contactInfo}</p>
          </div>
          }
        </div>
      </div>
    )

}
