import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../components/header/index.js";
import Footer from "../components/footer/footer.js";
import Menu from "./menu/index";
import "./layout.scss";


import Plc from "../images/Plc-illustration.svg"


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header />
      <div className="subHeader">
          <div className="SVGbackground">
            <div>
              <img src={Plc} />
              <div>
                <h1>Experts in PLC</h1>
                <h2>Complete Automation Ltd</h2>
              </div>
            </div>

          </div>
      </div>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
        
      </div>
      <Footer data="Complete Automation Ltd" >
          <Menu/>
      </Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
