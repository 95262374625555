import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Menu from './menu.js';

const MenuQuery = () => {
  const data = useStaticQuery(graphql`
  {
    datoCmsMenu(id: {eq: "DatoCmsMenu-70072734-en"}) {
      id
      title
      menuItems {
        ... on DatoCmsMenuTop {
          id
          title
          addMenuItem {
            id
            title
            linkToPost {
              ... on DatoCmsPage {
                id
                slug
                title
              }
            }
          }
        }
      }
    }
  }
  
    
  `)
  return <Menu data={data.datoCmsMenu.menuItems}></Menu>
}

export default MenuQuery