import React from "react";
import {
    useStaticQuery,
    graphql
} from "gatsby";
import Nav from './nav.js';

const NavQuery = (props) => {
    const data = useStaticQuery(graphql `
    {

        datoCmsMenu(id: {eq: "DatoCmsMenu-82241795-en"}) {
          id
          title
          menuItems {
            ... on DatoCmsMenuItem {
              id
              title
              linkToPost {
                ... on DatoCmsPage {
                  id
                  slug
                  title
                }
                ... on DatoCmsPost {
                  id
                  slug
                  title
                }
              }
              externalLink
              isThisExternalLink
              isThisACustomLink
              customLink
            }
            ... on DatoCmsMenuTop {
              id
              title
            }
          }
        }
      }
      
        
    
  `)
    return <Nav data = {data.datoCmsMenu.menuItems}   {...props}> </Nav>
}


export default NavQuery