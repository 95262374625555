import * as React from "react"


import Layout from "../components/layout"


const NotFoundPage = () => (
  <Layout>
   
    <p>404 page not found</p>
 

  </Layout>
)

export default NotFoundPage
