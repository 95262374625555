import React from "react";
import { Link } from "gatsby";
import './nav.scss';


export default function Nav(props) {
console.log("nav");
console.log(props.data[0].linkToPost.title);
console.log(props.data);
  return (
    <div className={"nav_main " + (props.mobileStatus ? "active" : "not-active") }>
     
      <div>

      {props.data.map((node) => (
        <div className="nav_item">
          {/* can't test something that isn't there */}
          {node.linkToPost && <Link className="internal" to={"/" + node.linkToPost.slug + "/"}> {node.linkToPost.title}</Link>}
          {node.isThisExternalLink && <a href={node.externalLink}>{node.title}</a> }
         
        </div>
      ))}
    </div>
    </div>
  )
}



